<template>
  <div>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
      <Steps :current="currentState" />
    </div>

    <div class="grid justify-items-center">
      <div class="bg-white shadow overflow-hidden lg:w-1/2 sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="inline text-lg leading-6 font-medium text-gray-900">
            {{ address }}@blockbase.dev
          </h3>
          <div v-if="loadingState === 'ready' && currentState < 3" class="">
            <div v-if="connectWSState === 'error'" class="ml-4 mb-2 mt-4 max-w-3xl text-sm text-red-700">
              Internal error connecting to Notion workspace.
              <br>
              This is our fault, not yours! We'll fix and get in touch with you personally.
            </div>
            <div class="float-right pb-4">
              <button @click="connectWorkspace" :disabled="connectWSState !== 'ready'" type="button" class="mt-5 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span v-if="connectWSState === 'ready'">
                  Connect to Notion
                </span>
                <span v-else-if="connectWSState === 'loading'" class="">
                  <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Loading...
                </span>
                <span v-else>
                  Error...
                </span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="loadingState === 'ready'">
          <div v-if="currentState > 3" class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <div class="px-4 py-5 sm:px-6 text-indigo-700 font-bold text-center">
              Your blockbase address is all set!
              <br>
              Forward emails to {{ address }}@blockbase.dev
            </div>
          </div>
          <div v-if="currentState > 2" class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Notion Workspace
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <img :src="info.workspace_icon" width="50" height="50" class="inline"> {{ info.workspace_name }}
                </dd>
              </div>
              
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Notion DB
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div v-if="disconnectDBState === 'error'" class="text-sm text-red-700 mt-4">
                    We ran into an internal error!<br>
                    You can reload to try again, or rest assured we will investigate and follow up with you.
                  </div>
                  <span v-else-if="currentState > 3" class="flex-grow">
                    {{ info.db_name }}
                  </span>
                  <span v-else class="flex-grow">
                    <label for="selectdb" class="block text-sm font-medium text-indigo-500">Please connect a Notion DB below:</label>
                    <div v-if="getNDBsState === 'ready'">
                      <select v-model="selecteddb" :disabled="connectDBState !== 'ready'" id="selectdb" name="notiondb" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                        <option v-for="db in dblist.data" :key="db.value" :value="db.value">{{ db.text }}</option>
                      </select>
                      <div class="text-sm text-gray-500 mt-4">
                        Not seeing the Notion DB you want? Be sure to "share" it with the email2notion integration.
                      </div>
                    </div>
                    <div v-else-if="getNDBsState === 'loading'" class="bg-yellow-50 p-4 font-bold rounded">
                      <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Loading...
                    </div>
                    <div v-else-if="getNDBsState === 'error'" class="text-sm text-red-700 mt-4">
                      We ran into an internal error fetching your Notion DBs.
                    </div>
                  </span>
                  <span class="ml-4 flex-shrink-0">
                    <button @click="disconnectDB" :disabled="disconnectDBState !== 'ready'" v-if="currentState > 3" type="button" class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span v-if="disconnectDBState === 'ready'">
                        Disconnect
                      </span>
                      <span v-else-if="disconnectDBState === 'loading'">
                        <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Loading...
                      </span>
                      <span v-else>
                        Error...
                      </span>
                    </button>
                    <button @click="connectDB" v-else-if="getNDBsState === 'ready'" :disabled="connectDBState !== 'ready'" type="button" class="mt-5 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span v-if="getNDBsState === 'loading' || connectDBState === 'loading'">
                        <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Loading...
                      </span>
                      <span v-else-if="getNDBsState === 'ready' && connectDBState === 'ready'">
                        Connect
                      </span>
                      <span v-else>
                        Error...
                      </span>
                    </button>
                  </span>
                  <div class="h-60" v-if="currentState < 3">
                  </div>
                </dd>
              </div>

              <div v-if="currentState > 3" class="py-4 sm:py-5 sm:grid sm:gap-4 sm:px-6">
                <Advanced
                  @tagsFieldChanged="tagsFieldChanged"
                  :dbId="info.db_id"
                  :dbName="info.db_name"
                  :tagsField="info.tags_field"
                  />
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-red-500" @click="showDangerZone=true">
                  Danger Zone
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="showDangerZone">
                    <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div class="w-0 flex-1 flex items-center">
                        <span class="ml-2 flex-1 w-0 truncate text-red-500">
                          Disconnect Workspace
                        </span>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <button @click="disconnectWorkspace" :disabled="disconnectWSState !== 'ready'" class="font-medium text-indigo-600 hover:text-indigo-500">
                          <span v-if="disconnectWSState === 'loading'">
                            <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Loading...
                          </span>
                          <span v-else-if="disconnectWSState === 'ready'">
                            Disconnect
                          </span>
                          <span v-else>
                            Error...
                          </span>
                        </button>
                      </div>
                    </li>
                    <div v-if="disconnectWSState === 'error'" class="px-5 py-4 flex-shrink-0 text-sm text-red-700 font-bold">
                      Internal error disconnecting from Notion.
                      <br>
                      We'll fix this!
                    </div>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div v-else-if="loadingState === 'loading'" class="flex justify-center mb-5">
          <div class="bg-yellow-50 w-3/4 p-4 rounded font-bold">
            <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
          </div>
        </div>
        <div v-else class="m-4 bg-red-100 w-3/4 p-4 rounded font-bold">
          Error :(
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import gql from 'graphql-tag'
import Steps from '@/components/Steps'
import Advanced from '@/components/Advanced'

export default {
  components: {
    Steps,
    Advanced,
  },
  data () {
    return {
      showDangerZone: false,
      info: {},
      dblist: [],
      selecteddb: null,
      showAdvanced: false,
      tagFields: [],
      loadingState: 'loading',
      connectWSState: 'ready',
      getNDBsState: 'loading',
      disconnectWSState: 'ready',
      connectDBState: 'ready',
      disconnectDBState: 'ready',
    }
  },
  computed: {
    address () {
      return this.$route.params.address
    },
    currentState () {
      if (this.info && this.info.connected && this.info.db_id) {
        return 4
      } else if (this.info && this.info.connected) {
        return 3
      }
      return 2
    }
  },
  async mounted() {
    this.loadingState = 'loading'
    if (!await this.getinfo()) {
      this.loadingState = 'error'
      return
    }
    if (this.info.connected && !this.info.db_id) {
      await this.getNotionDBs()
    }
    this.loadingState = 'ready'
  },
  methods: {
    tagsFieldChanged(newTagField) {
      this.info.tags_field = newTagField
    },
    async getinfo() {
      try {
        var result = await this.$apollo.query({
          query: gql`query ($address: String!, $auth_id: String!) {
              info: api_e2n_addresses(where: {address: {_eq: $address}, auth_id: {_eq: $auth_id}}) {
                workspace_name workspace_icon db_id db_name tags_field connected
            },
          }`,
          variables: {
            address: this.address,
            auth_id: this.$auth.user.value.sub
          },
          context: { headers: { authorization: 'Bearer ' + this.$auth.apitoken.value } }
        })
        this.info = result.data.info[0]
      } catch (err) {
        console.error(`Connect getinfo GQL: ${err}`)
        Sentry.captureException(new Error(`Connect getinfo GQL: ${err}`))
        return false
      }
      if (!this.info) {
        return false
      }
      Sentry.setContext("address", this.info)
      return true
    },
    async disconnectDB() {
      this.disconnectDBState = 'loading'
      try {
        var data = await this.$apollo.mutate({
          mutation: gql`mutation ( $address: String!, $auth_id: String!) {
            update_api_e2n_addresses(_set: {db_id: null, db_name: null, tags_field: null, token_failed: 0}, where: {address: {_eq: $address}, auth_id: {_eq: $auth_id}}) {
              returning {
                workspace_name workspace_icon db_id db_name connected
              }
            }
          }`,
          variables: {
            auth_id: this.$auth.user.value.sub,
            address: this.address,
          },
          context: { headers: { authorization: 'Bearer ' + this.$auth.apitoken.value } }
        })
        this.info = data.data.update_api_e2n_addresses.returning[0]
      } catch (err) {
        this.disconnectDBState = 'error'
        Sentry.captureException(new Error(`Connect disconnectDB GQL: ${err}`))
        return
      }
      await this.getNotionDBs()
      this.disconnectDBState = 'ready'
    },
    async getNotionDBs() {
      this.getNDBsState = 'loading'
      try {
        this.dblist = await this.axios.get(process.env.VUE_APP_API_URL + 'notiondbs?address=' + encodeURIComponent(this.$route.params.address), { headers: {'Authorization': 'Bearer ' + this.$auth.token.value }})
      } catch (err) {
        this.getNDBsState = 'error'
        Sentry.captureException(new Error(`Connect notiondbs GET: ${err}`))
        return
      }
      if (this.dblist.error) {
        if (this.dblist.error === 'Notion API token is invalid.') {
          this.getNDBsState = 'error'
          Sentry.captureException(new Error(`Connect notiondbs GET invalid API token: ${err}`))
          this.$router.push({ path: '/' })
        }
      }
      this.getNDBsState = 'ready'
    },
    async connectDB() {
      this.connectDBState = 'loading'
      var dbname = ''
      for (const db of this.dblist.data) {
        if (db.value === this.selecteddb) {
          dbname = db.text
        }
      }
      const post ={
          auth_id: this.$auth.user.value.sub,
          address: this.address,
          db_id: this.selecteddb,
          db_name: dbname,
      }
      var out = null
      try {
        out = await this.axios.post(process.env.VUE_APP_API_URL + 'connectdb', post, { headers: {'Authorization': 'Bearer ' + this.$auth.token.value }})
      } catch (err) {
        this.connectDBState = 'error'
        Sentry.captureException(new Error(`Connect connectdb POST: ${err}`))
        return
      }
      await this.getinfo()
      this.connectDBState = 'ready'
    },
    async connectWorkspace () {
      this.connectWSState = 'loading'
      var data = null
      try {
        data = await this.axios.get(process.env.VUE_APP_API_URL + 'notionauth?address=' + encodeURIComponent(this.address), { headers: {'Authorization': 'Bearer ' + this.$auth.token.value }})
      } catch (err) {
        this.connectWSState = 'error'
        Sentry.captureException(new Error(`Connect workspace notionauth GET: ${err}`))
        return
      }
      document.location = data.data.url
    },
    async disconnectWorkspace () {
      this.disconnectWSState = 'loading'
      try {
        var data = await this.$apollo.mutate({
          mutation: gql`mutation ( $address: String!, $auth_id: String!) {
            update_api_e2n_addresses(_set: {db_id: null, db_name: null, connected: false, token_failed: 0}, where: {address: {_eq: $address}, auth_id: {_eq: $auth_id}}) {
              returning {
                workspace_name workspace_icon db_id db_name connected
              }
            }
          }`,
          variables: {
            auth_id: this.$auth.user.value.sub,
            address: this.address,
          },
          context: { headers: { authorization: 'Bearer ' + this.$auth.apitoken.value } }
        })
        this.info = data.data.update_api_e2n_addresses.returning[0]
        this.disconnectWSState = 'ready'
      } catch (err) {
        this.disconnectWSState = 'error'
        Sentry.captureException(new Error(`Connect disconnect workspace GQL: ${err}`))
        return
      }
    }
  }
}
</script> 
