<template>
  <div>
    <button @click="toggleAdvanced" class="text-sm font-medium text-indigo-700 flex-1 inline-flex">
      <ChevronDownIcon v-if="showAdvanced" class="h-5 w-5 text-indigo-700" aria-hidden="true" />
      <ChevronRightIcon v-else class="h-5 w-5 text-indigo-700" aria-hidden="true" />
      Advanced
    </button>
    <div v-if="showAdvanced" class="py-4 sm:py-5 sm:grid sm:gap-4 sm:px-6">
      <span class="text-sm">
        We support "plus addressing". Include tags in your @blockbase.dev address and we'll set them in your Notion DB:
      </span>
      <div class="py-2 my-2 bg-indigo-50 rounded">
        <div class="text-center font-bold">youraddress+TAG1+Tag2@blockbase.dev</div>
        <div class="flex justify-center">
          <ArrowCircleDownIcon aria-hidden="true" class="h-10 w-10 text-indigo-700" />
        </div>
        <div class="flex justify-center">
          <img class="h-14" src="@/assets/plus_addressing.png" alt="Plus Addressing -> Notion DB Tags" />
        </div>
      </div>
      <div class="mt-2 sm:flex sm:items-start sm:justify-between">
        <div class="max-w-xl text-sm text-gray-500">
          <label for="tags-field" class="block text-medium font-bold text-gray-700">
            Tags in your Notion DB will be set in this field:
          </label>
          <div v-if="loadingState === 'ready' && tagFields.length < 1" class="mt-5 text-md font-medium text-indigo-700">
            Your Notion DB
            <span class="font-bold indigo-900">{{ dbName }}</span>
            doesn't have any Multi Select fields.
            <br>
            Add one and we can set tags based on plus addressing.
          </div>
          <select v-else-if="loadingState === 'ready'" :disabled="changeTFState !== 'ready'" id="tags-field" v-model="selectedTagsField" name="tags-field" class="mt-1 block w-full pl-3 pr-2 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option v-for="tf in tagFields" :key="tf.value" :value="tf.value">{{ tf.text }}</option>
          </select>
          <div v-else-if="loadingState === 'loading'" class="py-4 sm:py-5 sm:gap-4 sm:px-6 bg-yellow-50 font-bold p-4 rounded">
            <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
          </div>
          <div v-else-if="loadingState === 'error'" class="py-4 sm:py-5 sm:grid sm:gap-4 sm:px-6 font-bold text-black bg-red-100 rounded">
            Error loading your Notion DB fields.
            <br>
            This is not your fault - we will fix it and follow up in email.
            <br>
            Thanks for hanging in there with us!
          </div>
        </div>
        <div v-if="loadingState === 'ready' && tagFields.length > 0" class="mt-6 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <button @click="changeTagField" :disabled="changeTFState !== 'ready'" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
            <span v-if="changeTFState === 'ready'">
              Change field
            </span>
            <span v-else-if="changeTFState === 'loading'">
              <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Loading...
            </span>
            <span v-else-if="changeTFState === 'done'">
              Changed!
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import gql from 'graphql-tag'
import { ChevronRightIcon, ChevronDownIcon, ArrowCircleDownIcon } from '@heroicons/vue/solid'

export default {
  props: {
    tagsField: String,
    dbId: String,
    dbName: String,
  },
  emits: {
    tagsFieldChanged: String,
  },
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
    ArrowCircleDownIcon,
  },
  data () {
    return {
      showAdvanced: false,
      tagFields: [],
      selectedTagsField: null,
      loadingState: 'loading',
      changeTFState: 'ready',
    }
  },
  computed: {
    address () {
      return this.$route.params.address
    },
  },
  async mounted() {
  },
  methods: {
    async toggleAdvanced () {
      this.loadingState = 'loading'
      this.showAdvanced = !this.showAdvanced
      if (this.showAdvanced) {
        this.selectedTagsField = this.tagsField
        const post = {
            auth_id: this.$auth.user.value.sub,
            address: this.address,
            db_id: this.dbId,
        }

        try {
          const response = await this.axios.post(process.env.VUE_APP_API_URL + 'tagfields', post, { headers: {'Authorization': 'Bearer ' + this.$auth.token.value }})
          this.tagFields = response.data 
          this.loadingState = 'ready'
        } catch (err) {
          this.loadingState = 'error'
          Sentry.setContext("post", this.post)
          Sentry.captureException(new Error(`Advanced /tagfields POST: ${err}`))
        }
      }
    },
    async changeTagField () {
      this.changeTFState = 'loading'
      const post = {
          auth_id: this.$auth.user.value.sub,
          db_id: this.dbId,
          address: this.address,
          tags_field: this.selectedTagsField,
      }
      try {
        await this.axios.post(process.env.VUE_APP_API_URL + 'tagfield', post, { headers: {'Authorization': 'Bearer ' + this.$auth.token.value }})
        this.$emit('tagsFieldChanged', this.selectedTagsField)
        this.changeTFState = 'done'
        setTimeout(() => {
          this.changeTFState = 'ready'
        }, 4000)
      } catch (err) {
        this.loadingState = 'error'
        Sentry.setContext("post", this.post)
        Sentry.captureException(new Error(`Advanced /tagfield POST: ${response.data}`))
      }
    },
  }
}
</script> 
